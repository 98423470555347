import React from 'react';

function Post () {
    return (
        <section className="post">
            This is body of a post!
        </section>
    );
}

export default Post;
